export const all = {
  key: 'ALL',
  text: 'filterForm:type.value.all',
  value: 'ALL'
};

const corporate = {
  key: 'CORPORATE',
  text: 'filterForm:type.value.corporate',
  value: 'Corporate',
};

const operational = {
  key: 'Operational',
  text: 'filterForm:type.value.operational',
  value: 'Operational',
};

export const allTypeOptions = [all, corporate, operational];
