import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

import Base from '../Base';

function download(url) {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export class CompanyExporter extends Base {
  constructor(props) {
    super(props);

    this.state = {
      queryString: {}
    };
    this.exportToExcel = this.exportToExcel.bind(this);
    this.download = download.bind(this);
    this.setQueryString = this.setQueryString.bind(this);
    this.getTransactionStatus = this.getTransactionStatus.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      filter, selectedCompanyUuid, forceSearch, forceSelect
    } = this.props;

    if (!equal(prevProps.filter, filter)
      || (!equal(prevProps.forceSearch, forceSearch) && forceSearch)
    ) {
      const queryString = isEmpty(filter) ? {} : filter;
      this.setQueryString(queryString);
    }

    if (!equal(prevProps.selectedCompanyUuid, selectedCompanyUuid)
      || (!equal(prevProps.forceSelect, forceSelect) && forceSelect && !forceSearch)
    ) {
      const queryString = { 'filter.parent': selectedCompanyUuid };
      this.setQueryString(queryString);
    }
  }

  async exportToExcel() {
    const {
      t, exportCompanyListToExcel, sort
    } = this.props;

    const { queryString } = this.state;

    try {
      this.loading(true);
      this.clearNotification();
      this.showMessage(t('exportCompanyInfo'));

      const response = await super.dispatchWithAuth(exportCompanyListToExcel, queryString, sort);
      this.getTransactionStatus(response.data.transactionId);
    } catch (err) {
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      } else {
        this.loading(false);
        this.hideMessage(false);
      }
    }
  }

  async getTransactionStatus(transactionId) {
    const { t, getExportTransactionStatus } = this.props;
    try {
      const response = await super.dispatchWithAuth(getExportTransactionStatus, transactionId);
      if (response.data.attributes.status === 'FINISHED') {
        this.download(response.data.attributes.extraDetail.downloadUrl);
        this.loading(false);
        this.hideMessage(false);
      } else if (response.data.attributes.status === 'UNEXPECTED_ERROR') {
        super.handleError(t('fetchError'));
        this.loading(false);
      } else {
        setTimeout(() => {
          this.getTransactionStatus(transactionId);
        }, 500);
      }
    } catch (err) {
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      } else {
        this.loading(false);
        this.hideMessage(false);
      }
    }
  }

  loading(state) {
    this.setState({ loading: state });
  }

  setQueryString(queryString) {
    this.setState({ queryString });
  }

  render() {
    const {
      style, disabled, text, t
    } = this.props;
    const { loading } = this.state;

    return (
      <>
        <Button
          icon="download"
          loading={loading}
          onClick={this.exportToExcel}
          style={style}
          disabled={disabled}
        >
          {loading ? (t('exporting')) : text}
        </Button>
      </>
    );
  }
}

CompanyExporter.defaultProps = {
  filter: {},
  sort: {}
};

CompanyExporter.propTypes = {
  t: PropTypes.func.isRequired,
  exportCompanyListToExcel: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  filter: PropTypes.object,
  sort: PropTypes.object
};

export default withTranslation(['companies', 'common'])(CompanyExporter);
