export const all = {
  key: 'ALL',
  text: 'filterForm:status.value.all',
  value: 'ALL'
};

const active = {
  key: 'ACTIVE',
  text: 'filterForm:status.value.active',
  value: 'ACTIVE',
};

const blocked = {
  key: 'BLOCKED',
  text: 'filterForm:status.value.blocked',
  value: 'BLOCKED',
};
const archived = {
  key: 'ARCHIVED',
  text: 'filterForm:status.value.archived',
  value: 'ARCHIVED',
};
const historical = {
  key: 'HISTORICAL',
  text: 'filterForm:status.value.historical',
  value: 'HISTORICAL',
};

export const allStatusOptions = [all, active, blocked, archived, historical];
