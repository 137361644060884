import moment from 'moment';

const defaultFormat = 'LL LT';

export function getLocalTimeFormat(timestamp, format = defaultFormat) {
  return moment.utc(timestamp).local().format(format);
}

export function getLocalTimeFormatUnix(timestamp, format = defaultFormat) {
  return moment.unix(timestamp).format(format);
}

export function formatTimestamp(timestamp, format = defaultFormat) {
  let formatter = getLocalTimeFormat;
  if (Number.isInteger(timestamp)) {
    formatter = getLocalTimeFormatUnix;
  }

  return formatter(timestamp, format);
}
