import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover, List } from 'antd';
import { withTranslation } from 'react-i18next';

import Base from '../Base';

export class PhysicalLocations extends Base {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      hasFetched: false,
      list: []
    };

    this.onClick = this.onClick.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  get hasData() {
    const { list, loading } = this.state;
    return !loading && list.length > 0;
  }

  async fetchData() {
    const { t, getCompanyPhysicalLocations, companyUuid } = this.props;

    try {
      this.loading(true);

      const result = await super.dispatchWithAuth(
        getCompanyPhysicalLocations,
        companyUuid
      );

      this.setState({
        list: result || []
      });

      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
      this.setState({ hasFetched: true });
    }
  }

  onClick() {
    const { hasFetched } = this.state;
    if (!hasFetched) {
      this.fetchData();
    }
  }

  toggleVisibility(state) {
    this.setState({
      visible: state
    });
  }

  loading(state) {
    this.setState({
      loading: state
    });
  }

  renderData() {
    const { loading, list } = this.state;
    return (
      <List
        size="small"
        loading={loading}
        bordered
        style={{ minWidth: 100, mineHeight: 100 }}
        dataSource={list}
        renderItem={(item) => (
          <List.Item>{Base.nameWithStatus(item.name, item.status)}</List.Item>
        )}
        pagination={this.hasData}
      />
    );
  }

  render() {
    const { visible } = this.state;
    const { t } = this.props;

    return (
      <Popover
        placement="right"
        trigger="click"
        content={this.renderData()}
        visible={visible}
        onVisibleChange={this.toggleVisibility}
      >
        <Icon
          className="show-physical-locations"
          type="pushpin"
          style={{ marginLeft: 5 }}
          onClick={this.onClick}
          title={t('showPhysicalLocations')}
        />
      </Popover>
    );
  }
}

PhysicalLocations.propTypes = {
  t: PropTypes.func.isRequired,
  getCompanyPhysicalLocations: PropTypes.func.isRequired,
  companyUuid: PropTypes.string.isRequired
};

export default withTranslation(['companies', 'common'])(PhysicalLocations);
