import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import Companies from './Containers/Companies';
import BatchCompany from './Containers/BatchCompany';
import CompanyTransactionView from './Containers/CompanyTransactionView';
import BatchTransactionsList from './Containers/BatchTransactionsList';
/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/

const Root = ({ route }) => (
  <div>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </div>
);

Root.propTypes = {
  route: PropTypes.object.isRequired
};

export const routes = [{
  component: Root,
  routes: [
    {
      path: '/company',
      exact: true,
      component: Companies,
    },
    {
      path: '/company/batch-process',
      exact: true,
      component: BatchCompany,
    },
    {
      path: '/company/transaction/:uuid',
      exact: true,
      component: CompanyTransactionView,
    },
    {
      path: '/company/batch-transaction',
      exact: true,
      component: BatchTransactionsList,
    }
  ]
}];
