import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  SubmissionStep,
  SUBMISSION_INITIALSING,
  SUBMISSION_SUBMITTING,
  SUBMISSION_SUBMITTED,
  SUBMISSION_UNEXPECTED_ERROR,
  SUBMISSION_STEP
} from 'componentlibrary';

import Base from '../Base';

export class BatchCompanySubmissionStep extends Base {
  constructor() {
    super();
    this.state = {
      submissionState: SUBMISSION_INITIALSING,
      submissionDetail: {}
    };
  }

  componentDidUpdate(prevProps) {
    const { batchStep } = this.props;
    const prevBatchStep = prevProps.batchStep;

    if (batchStep !== SUBMISSION_STEP) {
      return;
    }

    if (batchStep !== prevBatchStep) {
      this.submitPayload();
    }
  }

  setSubmissionStatus(status, transactionId) {
    const newState = {
      submissionState: status,
      submissionDetail: {}
    };

    if (newState.submissionState === SUBMISSION_SUBMITTED) {
      newState.submissionDetail = {
        transactionViewLink: `/company/transaction/${transactionId}`
      };
    }

    this.setState(newState);
  }

  async submitPayload() {
    const {
      batchStep, batchState, submitBatchPayload, t
    } = this.props;

    if (!batchState
      || batchStep !== SUBMISSION_STEP
      || !batchState.uploadRequestResponse
      || !batchState.uploadRequestResponse.payloadForSubmission) {
      return;
    }

    let result;

    this.setSubmissionStatus(SUBMISSION_SUBMITTING);

    try {
      result = await submitBatchPayload({
        batch: batchState.uploadRequestResponse.payloadForSubmission,
        returnDetailedResponse: false
      });
    } catch (err) {
      console.error(err);
      super.handleError(t('unexpectedErrorSubmission'));
      this.setSubmissionStatus(SUBMISSION_UNEXPECTED_ERROR);
      return;
    }

    if (result) {
      this.setSubmissionStatus(SUBMISSION_SUBMITTED, result.transactionUuid);
    } else {
      this.setSubmissionStatus(SUBMISSION_UNEXPECTED_ERROR);
    }
  }

  render() {
    const { batchStep, batchState, handleStepChange } = this.props;
    const { submissionState, submissionDetail } = this.state;

    return (
      <SubmissionStep
        batchStep={batchStep}
        batchState={batchState}
        handleStepChange={handleStepChange}
        submissionState={submissionState}
        submissionDetail={submissionDetail}
      />
    );
  }
}

BatchCompanySubmissionStep.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
  batchStep: PropTypes.any.isRequired,
  batchState: PropTypes.object.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  submitBatchPayload: PropTypes.func.isRequired,
  getXlsxLogForBatchTransaction: PropTypes.func.isRequired
};

export default withTranslation(['batchProcess'])(BatchCompanySubmissionStep);
