import { connect } from 'react-redux';

import PhysicalLocations from '../../Components/PhysicalLocations';
import { getCompanyPhysicalLocations } from '../../reducers/company';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyPhysicalLocations(...args) {
    return getCompanyPhysicalLocations(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(PhysicalLocations));
