import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import {
  SUBMISSION_STEP,
  VALIDATION_STEP,
  ValidationStep,
} from 'componentlibrary';

export class BatchCompanyValidationStep extends React.Component {
  constructor() {
    super();
    this.onSubmission = this.onSubmission.bind(this);
  }

  onSubmission() {
    const { batchState, handleStepChange } = this.props;
    handleStepChange(SUBMISSION_STEP, batchState);
  }

  render() {
    const { batchStep, batchState, handleStepChange } = this.props;

    if (batchStep !== VALIDATION_STEP || !batchState) {
      return null;
    }

    const { uploadRequestResponse } = batchState;

    let validationState = null;
    let validationDetail = null;
    if (uploadRequestResponse) {
      validationState = uploadRequestResponse.validationStatus || null;
      validationDetail = uploadRequestResponse.validationDetail;
    }

    return (
      <ValidationStep
        batchStep={batchStep}
        batchState={batchState}
        handleStepChange={handleStepChange}
        validationState={validationState}
        validationDetail={validationDetail}
        onSubmission={this.onSubmission}
      />
    );
  }
}

BatchCompanyValidationStep.propTypes = {
  batchStep: PropTypes.any.isRequired,
  batchState: PropTypes.object.isRequired,
  handleStepChange: PropTypes.func.isRequired
};

export default withTranslation(['batchProcess'])(BatchCompanyValidationStep);
