const AUTH_SET_ACTION = 'AUTH_SET';

export function authDefaultState() {
  return {
    getIdToken: null
  };
}

export function setAuth(dispatch, getIdToken) {
  return dispatch({
    type: AUTH_SET_ACTION,
    payload: {
      getIdToken
    }
  });
}

export default (state = authDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case AUTH_SET_ACTION:
      return {
        getIdToken: payload.getIdToken
      };

    default:
      return state;
  }
};
