import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Empty } from 'componentlibrary';
import {
  Row, Col, Tree, Spin, Card
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Base from '../Base';
import PhysicalLocations from '../../Containers/PhysicalLocations';
import { inlineLoader } from '../../sass/modules/loader.module.scss';
import styles from './styles.module.scss';

export class CompanyStructure extends Base {
  static nameWithPhysicalLocationTrigger(uuid) {
    return (
      <PhysicalLocations companyUuid={uuid} />
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hasFetched: false,
      hasNodeClick: false,
    };

    this.loading = this.loading.bind(this);
    this.handleCompanyStuctureBack = this.handleCompanyStuctureBack.bind(this);
  }

  componentDidMount() {
    const { fetchData } = this.props;
    if (!fetchData) {
      return;
    }

    this.fetchStructure();
  }

  componentDidUpdate(prevProps) {
    const {
      filter, selectedCompanyName, setSelectedCompanyUuid, forceSearch, forceSelect, allCompanies
    } = this.props;

    if (!equal(prevProps.filter, filter)
      || (!equal(prevProps.forceSearch, forceSearch) && forceSearch)
    ) {
      this.fetchStructure();
      this.handleNodeClick(false);
      return;
    }

    if (!equal(prevProps.selectedCompanyName, selectedCompanyName)
      || (!equal(prevProps.forceSelect, forceSelect) && forceSelect && !forceSearch)
    ) {
      const selectedCompany = allCompanies.find((item) => item.name === selectedCompanyName);
      const parents = allCompanies.map((item) => (item.parent === null ? '' : item.parent));
      if (parents.includes(selectedCompany.uuid)) {
        const selectedCompanyUuid = selectedCompany.uuid;
        setSelectedCompanyUuid(selectedCompanyUuid);
        this.fetchStructureByParent(selectedCompanyUuid);
        this.handleNodeClick(true);
      }
    }
  }

  get isEmptyStructure() {
    const { structure } = this.props;
    const { data } = structure;

    return Object.keys(data).length === 0;
  }

  get hasFilter() {
    const { filter } = this.props;

    return Object.keys(filter).length > 0;
  }

  get resultCount() {
    const { structure } = this.props;
    const { meta = {} } = structure;
    const { resultCount = 0 } = meta;

    return resultCount;
  }

  async fetchStructure() {
    const {
      t, getCompanyStructure, filter, setForceSearch
    } = this.props;

    try {
      this.loading(true);
      await super.dispatchWithAuth(getCompanyStructure, filter);
      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
      setForceSearch(false);
    }
  }

  async fetchStructureByParent(parent) {
    const { t, getCompanyStructureByParent, setForceSelect } = this.props;

    try {
      this.loading(true);
      await super.dispatchWithAuth(getCompanyStructureByParent, parent);
      super.onDataRetrieved();
    } catch (err) {
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
      setForceSelect(false);
    }
  }

  matchedWithFilter(company) {
    const { loading } = this.state;
    return !loading && this.hasFilter && company.matched;
  }

  unmatchedWithFilter(company) {
    const { loading } = this.state;
    return !loading && this.hasFilter && !company.matched;
  }

  treeNode(children, index = 0) {
    if (!children) {
      return null;
    }

    const { TreeNode } = Tree;

    return children.map((company) => (
      <TreeNode
        selectable={false}
        title={this.companyTitle(company, index)}
        key={company.uuid}
        className={classnames({
          [styles.unmatched]: this.unmatchedWithFilter(company),
          [styles.matched]: this.matchedWithFilter(company)
        })}
      >
        {this.treeNode(company.children, 1)}
      </TreeNode>
    ));
  }

  companyTitle(company, key) {
    const { name, status, uuid } = company;
    return (
      <>
        <span onClick={() => (key > 0 ? this.handleClick(name) : null)}>
          {Base.nameWithStatus(name, status)}
        </span>
        {CompanyStructure.nameWithPhysicalLocationTrigger(uuid)}
      </>
    );
  }

  loading(state) {
    this.setState({ loading: state });
  }

  handleNodeClick(state) {
    this.setState({
      ...this.state,
      loading: true,
      hasNodeClick: state
    });
  }

  handleClick(name) {
    const { setSelectedCompanyName, setForceSelect } = this.props;
    setSelectedCompanyName(name);
    setForceSelect(true);
  }

  handleCompanyStuctureBack() {
    this.fetchStructure();
    this.handleNodeClick(false);
  }

  render() {
    const { t, structure } = this.props;
    const { loading, hasNodeClick } = this.state;
    const { data } = structure;
    const { resultCount } = this;

    if (!loading && this.isEmptyStructure) {
      return (
        <Empty message={t('common.noRecordsFound')} />
      );
    }

    return (
      <Spin
        spinning={loading}
        size="large"
        style={{ marginTop: 20 }}
        className={inlineLoader}
      >
        {
          resultCount > 0 && (
            <Row style={{ marginBottom: 20 }}>
              <Col>
                {t('resultCount', {
                  resultCount,
                  item: resultCount > 1 ? t('companies').toLowerCase() : t('company').toLowerCase()
                })}
              </Col>
            </Row>
          )
        }
        {
          !this.isEmptyStructure && (
            <Card>
              {hasNodeClick && (
              <span>
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  onClick={this.handleCompanyStuctureBack}
                  style={{ cursor: 'pointer' }}
                  swapOpacity
                />
              </span>
              )}
              <Tree
                defaultExpandAll
              >
                {this.treeNode([data])}
              </Tree>
            </Card>
          )
        }
      </Spin>
    );
  }
}

CompanyStructure.defaultProps = {
  fetchData: true,
  filter: {}
};

CompanyStructure.propTypes = {
  t: PropTypes.func.isRequired,
  structure: PropTypes.object.isRequired,
  getCompanyStructure: PropTypes.func.isRequired,
  getCompanyStructureByParent: PropTypes.func.isRequired,
  setForceSearch: PropTypes.func,
  forceSearch: PropTypes.bool,
  getIdToken: PropTypes.func.isRequired,
  fetchData: PropTypes.bool,
  filter: PropTypes.object
};

export default withTranslation(['companies'])(CompanyStructure);
