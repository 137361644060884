import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => ({
  getIdToken: state.auth.getIdToken,
  ...ownProps,
});

export default function (component) {
  return connect(mapStateToProps)(component);
}
