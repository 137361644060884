import Config from '../Config';

const COMPANY_GET_LIST_ACTION = 'COMPANY_GET_LIST';
const COMPANY_GET_STRUCTURE_ACTION = 'COMPANY_GET_STRUCTURE';
const COMPANY_CLEAR_DATA_ACTION = 'COMPANY_CLEAR_DATA';

export function companyDefaultState() {
  return {
    list: {
      meta: {},
      data: []
    },
    structure: {
      meta: {},
      data: {}
    }
  };
}

export const defaultSort = {
  key: 'name',
  reverse: false
};

const sortQuery = (sort = defaultSort) => {
  if (sort.reverse === true) {
    return `-${sort.key}`;
  }

  return sort.key;
};

const serialize = (filter = {}, prefix = '?') => {
  const query = [];

  Object.keys(filter).forEach((key) => {
    query.push(`${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`);
  });

  if (query.length === 0) {
    return '';
  }

  return `${prefix}${query.join('&')}`;
};

async function api(dispatch, token, params) {
  const {
    query = null,
    endpoint = 'company',
    method = 'GET',
    body = null,
    contentType = 'application/json'
  } = params;

  const fetchConfig = {
    method,
    headers: {
      'content-type': contentType,
      authorization: token
    }
  };

  if (body) {
    if (contentType === 'application/json') {
      fetchConfig.body = JSON.stringify(body);
    } else {
      fetchConfig.body = body;
    }
  }

  let fetchUrl = `${Config.apiGateway.COMPANY_API_URL}/${endpoint}`;
  if (query) {
    fetchUrl += query;
  }

  const result = await fetch(
    fetchUrl,
    fetchConfig
  );

  const payload = await result.json();

  if (!result.ok) {
    throw Object.assign(new Error(), {
      status: result.status,
      message: payload.errors
    });
  }

  return payload;
}

export async function getCompanyList(dispatch, token, filter, sort) {
  const params = {
    query: `?sort=${sortQuery(sort)}&internal.returnMetaData=true${serialize(filter, '&')}`
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: COMPANY_GET_LIST_ACTION,
    payload
  });
}

export async function getCompanyStructure(dispatch, token, filter) {
  const params = {
    query: serialize(filter),
    endpoint: 'company-structure'
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: COMPANY_GET_STRUCTURE_ACTION,
    payload
  });
}

export async function getCompanyStructureByParent(dispatch, token, parent) {
  const params = {
    query: `?filter.parent=${parent}`,
    endpoint: 'company-structure'
  };

  const payload = await api(dispatch, token, params);

  dispatch({
    type: COMPANY_GET_STRUCTURE_ACTION,
    payload
  });
}

export async function getCompany(dispatch, token, uuid) {
  const params = {
    endpoint: `company/${uuid}`
  };

  return api(dispatch, token, params);
}

// get contractor companies list
export async function getContractorCompanies(dispatch, token, queryParams) {
  const params = {
    method: 'GET',
    query: `?limit=${queryParams.limit}&offset=${queryParams.offset}`,
    endpoint: 'contractor-company'
  };

  return api(dispatch, token, params);
}

export async function getCompanyPhysicalLocations(dispatch, token, uuid) {
  const response = await getCompany(dispatch, token, uuid);

  const { data } = response;
  const { attributes } = data;
  const { physicalLocations } = attributes;

  return physicalLocations;
}

export async function exportCompanyListToExcel(dispatch, token, queryString, sort) {
  const params = {
    query: `?sort=${sortQuery(sort)}${serialize(queryString, '&')}`,
    endpoint: 'company/export-company-init'
  };

  return api(dispatch, token, params);
}

export async function getExportTransactionStatus(dispatch, token, transactionId) {
  const params = {
    endpoint: `company/export-company-status/${transactionId}`
  };

  return api(dispatch, token, params);
}

export function clearCompanyData(dispatch) {
  dispatch({
    type: COMPANY_CLEAR_DATA_ACTION
  });
}

// POST batch-xlsx-processor/{locale}
export async function batchXlsxProcessor(dispatch, token, locale, file) {
  const localForSubmission = locale || 'en';

  const params = {
    method: 'POST',
    endpoint: `batch-xlsx-processor/${localForSubmission}`,
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    body: file
  };

  return api(dispatch, token, params);
}

// POST batch-company
export async function batchCompany(dispatch, token, payload) {
  const params = {
    method: 'POST',
    endpoint: 'batch-company',
    body: payload
  };

  return api(dispatch, token, params);
}

// Batch transaction status
export async function getBatchStatus(dispatch, token, transactionId) {
  const params = {
    method: 'GET',
    endpoint: 'batch-status',
    query: `?transactionId=${transactionId}`
  };

  return api(dispatch, token, params);
}

// GET /batch-xlsx-log/{transactionId}/{locale}
export async function getXlsxLogForBatchTransaction(dispatch, token, transactionId, locale) {
  const params = {
    method: 'GET',
    endpoint: `batch-xlsx-log/${transactionId}/${locale}`
  };

  return api(dispatch, token, params);
}

// get batch transaction list
export async function getBatchTransactions(dispatch, token) {
  const params = {
    method: 'GET',
    endpoint: 'batch-transactions'
  };

  return api(dispatch, token, params);
}

export default (state = companyDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case COMPANY_GET_LIST_ACTION:
      return {
        ...state,
        list: {
          meta: payload.meta,
          data: payload.data.map((item) => item.attributes)
        }
      };

    case COMPANY_GET_STRUCTURE_ACTION:
      return {
        ...state,
        structure: {
          meta: payload.meta,
          data: payload.data ? payload.data.attributes : {}
        }
      };

    case COMPANY_CLEAR_DATA_ACTION:
      return {
        ...companyDefaultState()
      };

    default:
      return state;
  }
};
