import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
  Row, Col, Spin, Divider, Button, Tag, Typography
} from 'antd';
import { ResponsiveTable, Empty } from 'componentlibrary';
import Base from '../Base';
import { formatTimestamp } from '../../Utils/TimeUtils';
import styles from './styles.scss';

const { Title } = Typography;

export class BatchTransactionsList extends Base {
  static getTransactionStatus(total, success, fail) {
    const processedCount = success + fail;
    if (processedCount === 0) {
      return 'Pending';
    } if (processedCount >= total) {
      return 'Completed';
    }
    return 'In Progress';
  }

  static renderTag(item, color) {
    return (
      <Tag color={item > 0 && color}>{ item }</Tag>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fetchResponse: []
    };
    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    this.fetchList();
  }

  loading(state) {
    this.setState({ loading: state });
  }

  get tableHeader() {
    const { t } = this.props;
    const headerItem = (key, label, width) => ({
      key,
      label,
      width,
    });

    return [
      headerItem('initiatedBy', t('transaction:initiatedBy'), '20%'),
      headerItem('initiatedAt', t('transaction:initiatedAt'), '30%'),
      headerItem('updatedAt', t('transaction:updatedAt'), '30%'),
      headerItem('status', t('transaction:status'), '20%'),
      headerItem('success', t('transaction:success'), '10%'),
      headerItem('fail', t('transaction:fail'), '10%'),
      headerItem('total', t('transaction:total'), '10%'),
      headerItem('actions', '', '15%'),
    ];
  }

  async fetchList() {
    const { t, getBatchTransactions } = this.props;

    try {
      this.loading(true);
      const payload = await super.dispatchWithAuth(getBatchTransactions);
      this.setState({ fetchResponse: payload.data });
      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        console.error(err);
        super.handleError(t('transaction:fetchError'));
      }
    } finally {
      this.loading(false);
    }
  }

  transformDataForDisplay(data) {
    return data.map((_item) => {
      const item = { ..._item.attributes };
      item.initiatedAt = formatTimestamp(item.initiatedAt, 'L LTS');
      item.updatedAt = formatTimestamp(item.updatedAt, 'L LTS');
      item.status = BatchTransactionsList.getTransactionStatus(item.total, item.success, item.fail);
      item.success = BatchTransactionsList.renderTag(item.success, 'green');
      item.fail = BatchTransactionsList.renderTag(item.fail, 'volcano');
      item.actions = this.renderActions(item);
      return item;
    });
  }

  renderActions(item) {
    const { t } = this.props;
    return (
      <Col style={{ marginTop: '2px' }}>
        <Link
          title={t('transactionView')}
          to={`/company/transaction/${item.transactionId}`}
        >
          <Button
            icon="eye"
            title={t('view')}
          />
        </Link>
      </Col>
    );
  }

  render() {
    const {
      t
    } = this.props;
    const {
      loading, fetchResponse
    } = this.state;

    return (
      <Media query="(max-width: 769px)">
        {() => (
          <>
            <Row type="flex" justify="center" align="top">
              <Col span={24}>
                <Title level={4} className={styles.title}>
                  {t('companies:transactionsList')}
                </Title>
                <Divider />
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <Link to="/company/batch-process">
                  <Button
                    type="primary"
                    icon="user-add"
                  >
                    {t('companies:newBatchProcess')}
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Spin
                  spinning={loading}
                  size="large"
                  style={{
                    marginTop: 40,
                    position: 'fixed',
                    top: '20%',
                    bottom: '20%'
                  }}
                >
                  <ResponsiveTable
                    header={this.tableHeader}
                    data={this.transformDataForDisplay(fetchResponse)}
                  />
                  {!loading && fetchResponse.length === 0 && (
                    <Empty message={t('common:noRecordsFound')} />
                  )}
                </Spin>
              </Col>
            </Row>
          </>
        )}
      </Media>
    );
  }
}

BatchTransactionsList.defaultProps = {
  list: [],
};

BatchTransactionsList.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.array,
};

export default withTranslation()(BatchTransactionsList);
