import { connect } from 'react-redux';

import CompanyTransactionView from '../../Components/CompanyTransactionView';
import {
  getBatchStatus,
  getXlsxLogForBatchTransaction
} from '../../reducers/company';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getBatchSubmissionStatus(...args) {
    return getBatchStatus(dispatch, ...args);
  },
  getXlsxLogForBatchTransaction(...args) {
    return getXlsxLogForBatchTransaction(dispatch, ...args);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithDefaults(CompanyTransactionView));
