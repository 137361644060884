import { connect } from 'react-redux';

import CompanyList from '../../Components/CompanyList';
import { getCompanyList } from '../../reducers/company';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  list: state.company.list,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyList(...args) {
    return getCompanyList(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(CompanyList));
