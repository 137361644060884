import { combineReducers } from 'redux';
import { appConfig } from 'componentlibrary';
import auth from './auth';
import company from './company';

const appReducer = combineReducers({
  appConfig,
  auth,
  company
});

export default (state, action) => appReducer(state, action);
