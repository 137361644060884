import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Media from 'react-media';
import {
  Col, Row, Input, Select, Card, Form, Button, Radio,
} from 'antd';
import { allStatusOptions } from '../../Utils/StatusData';
import { allTypeOptions } from '../../Utils/TypeData';

const ALL = 'ALL';
export class CompanyFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchBy: 'name',
      filter: {},
      name: '',
      parent: '',
      status: ALL,
      type: ALL,
    };

    this.handleNameInputChange = this.handleNameInputChange.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.resetAllFilter = this.resetAllFilter.bind(this);
    this.handleDropDownValue = this.handleDropDownValue.bind(this);
  }

  async handleDropDownValue(value) {
    this.setState({ searchBy: value, name: '', parent: '' });
    await this.clearFilter('parent');
    await this.clearFilter('name');
  }

  handleNameInputChange(e, searchBy) {
    this.setState({ [searchBy]: e.target.value });
    this.applyFilter(searchBy, e.target.value, true);
  }

  async handleTypeChange(key, value) {
    this.setState({ [key]: value });
    let currentKey = key;
    let currentValue = value;
    if (currentValue === 'Operational') {
      await this.clearFilter('corporateCompany');
      currentKey = 'operational';
    } else if (currentValue === 'Corporate') {
      currentKey = 'corporateCompany';
      await this.clearFilter('operational');
    } else {
      await this.clearFilter('operational');
      await this.clearFilter('corporateCompany');
      return;
    }
    currentValue = true;
    this.applyFilter(currentKey, currentValue);
  }

  handleValueChange(key, value) {
    this.setState({ [key]: value });
    if (value === ALL) {
      this.clearFilter(key);
      return;
    }
    this.applyFilter(key, value);
  }

  applyFilter(key, value, clearOnEmpty = false) {
    const { filter } = this.state;
    const newFilterValue = { ...filter };

    if (clearOnEmpty && !value) {
      this.clearFilter(key);
      return;
    }

    let filterValue = value;

    if (typeof filterValue === 'string') {
      filterValue = value.trim();
    }

    newFilterValue[`filter.${key}`] = filterValue;
    this.setState({
      filter: newFilterValue
    });
  }

  resetAllFilter() {
    const { onFilter } = this.props;
    this.setState({
      filter: {},
      status: ALL,
      type: ALL,
      name: '',
      parent: ''
    });
    onFilter({});
  }

  clearFilter(key) {
    const { filter } = this.state;
    const filterKey = `filter.${key}`;
    if (filterKey in filter) {
      const newFilterValue = { ...filter };
      delete newFilterValue[filterKey];
      this.setState({
        filter: newFilterValue
      });
    }
  }

  render() {
    const {
      t, renderButtons, showFilter, onFilter
    } = this.props;
    const {
      status, type, filter, name, parent, searchBy
    } = this.state;
    const searchKeys = ['name', 'parent'];

    return (
      <Media query="(max-width: 769px)">

        {(isMobile) => (
          <>
            <Card
              title={t('filterForm:title')}
              extra={renderButtons && renderButtons()}
              actions={[
                <>
                  <Button
                    icon="search"
                    style={{
                      float: 'right',
                      marginRight: '10px',
                    }}
                    type="primary"
                    onClick={() => onFilter(filter)}
                  >
                    Search
                  </Button>
                  <Button
                    icon="redo"
                    style={{
                      float: 'right',
                      marginRight: '10px',
                    }}
                    onClick={this.resetAllFilter}
                  >
                    Reset
                  </Button>
                </>]}
            >
              <Form onSubmit={this.onFormSubmit}>
                <Row gutter={[10, 10]}>

                  <h3>{t('searchBoxTitle')}</h3>
                  <Col md={12} xs={24} className="search">
                    <Input.Group compact>
                      <Select
                        defaultValue="name"
                        dropdownMatchSelectWidth={false}
                        onChange={(value) => this.handleDropDownValue(value)}
                        value={searchBy}
                        style={{ width: isMobile ? '100%' : 145 }}
                      >
                        {
                      searchKeys.map((key) => (
                        <Select.Option key={key} value={key}>{t(key)}</Select.Option>
                      ))
                    }
                      </Select>
                      <Input
                        allowClear
                        onChange={(e) => this.handleNameInputChange(e, searchBy)}
                        value={searchBy === 'name' ? name : parent}
                        style={{ width: isMobile ? '100%' : 370 }}
                        placeholder={t(`searchPlaceHolder.${searchBy}`)}
                      />
                    </Input.Group>
                  </Col>
                </Row>

                <br />
                {showFilter && (
                <>
                  <Row gutter={[10, 10]}>

                    <Row>
                      <Col md={12} xs={24}>
                        <h3>{t('filterForm:type.label')}</h3>
                        <Radio.Group
                          value={type}
                          style={{ display: 'block' }}
                          onChange={({ target: { value } }) => this.handleTypeChange('type', value)}
                        >
                          {allTypeOptions.map((typeOption) => (
                            <Radio key={typeOption.value} value={typeOption.value}>
                              {t(`${typeOption.text}`)}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Col>
                      <Col md={12} xs={24}>
                        <h3>{t('filterForm:status.label')}</h3>
                        <Radio.Group
                          value={status}
                          style={{ display: 'block' }}
                          onChange={({ target: { value } }) => this.handleValueChange('status', value)}
                        >
                          {allStatusOptions.map((statusOption) => (
                            <Radio key={statusOption.value} value={statusOption.value}>
                              {t(`${statusOption.text}`)}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Col>
                    </Row>

                  </Row>
                </>
                )}
              </Form>
            </Card>
          </>
        )}

      </Media>
    );
  }
}

CompanyFilter.defaultProps = {
  renderButtons: null
};

CompanyFilter.propTypes = {
  t: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  renderButtons: PropTypes.func,
  showFilter: PropTypes.bool.isRequired,
};

export default withTranslation(['companies'])(CompanyFilter);
